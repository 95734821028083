<template>
  <div class="time-range-pick">
    <vue-timepicker
      style="width: 50%; display: flex; float: left"
      placeholder="--:--"
      manual-input
      hide-dropdown
      :disabled="disabled"
      v-model="start"
    ></vue-timepicker>
    <vue-timepicker
      style="width: 50%; display: flex"
      placeholder="--:--"
      manual-input
      hide-dropdown
      :disabled="disabled"
      v-model="end"
    ></vue-timepicker>
  </div>
</template>
<script>
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  props: ["value", "disabled"],
  data() {
    return {
      start: "",
      end: "",
    };
  },
  components: {
    VueTimepicker,
  },
  methods: {
    store() {
      this.value = this.start + "-" + this.end;
      this.$emit("input", this.value);
    },
  },
  mounted() {
    if (this.value) {
      let val = this.value.trim().split("-");
      this.start = val[0];
      this.end = val[1];
    }
  },
  watch: {
    start(val) {
      this.start = val.replace("mm", "00");
      this.store();
    },
    end(val) {
      this.end = val.replace("mm", "00");
      this.store();
    },
    value(val) {
      if (val) {
        let v = val.trim().split("-");
        this.start = v[0];
        this.end = v[1];
      }
    },
  },
};
</script>
<style>
.time-range-pick {
  width: 130px;
}
.time-range-pick .display-time {
  width: 100% !important;
}
.vue__time-picker input.display-time.disabled,
.vue__time-picker input.display-time:disabled {
  color: #9c9b9b;
}
</style>
